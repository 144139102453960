import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Terms } from '@/types.d'
import DefaultLink from '../DefaultLink'
import PlaceholderImg from './placeholder.png'

export interface PersonCardProps {
  name: string
  email: string
  phone: string
  secondaryPhone: string
  sections: any
  tasks: any
  strings: any
  titles: {
    nodes: Array<Terms>
  }
  img: any
}

const PersonCard = ({ name, email, phone, secondaryPhone, sections, tasks, titles, img, strings }: PersonCardProps) => {
  const personimage = img && getImage(img?.localFile)

  return (
    <section className="bg-white rounded-2xl shadow-lg px-5 sm:px-10 xl:px-28 py-5 sm:py-10 my-6 text-xl">
      <div className="grid  md:grid-cols-3 gap-10">
        <div>
          <div className="max-w-[200px] overflow-hidden">
            {personimage ? (
              <GatsbyImage className="" image={personimage} alt={img?.altText ? img?.altText : 'Image'} />
            ) : (
              <img alt="Henkilökuva" src={PlaceholderImg} />
            )}
          </div>
        </div>
        <div>
          <div className="mb-8">
            {name && <h2 className="text-2xl md:text-4xl mb-1">{name}</h2>}
            {titles?.nodes?.map(item => {
              return <p key={item.id}>{item.name}</p>
            })}
          </div>
          {phone && (
            <div>
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
          )}
          {secondaryPhone && (
            <div>
              <a href={`tel:${secondaryPhone}`}>{secondaryPhone}</a>
            </div>
          )}
          {email && (
            <div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
        </div>
        <div>
          <div className="mb-8">
            <h2 className="text-2xl md:text-4xl mb-1">{strings.tasks}</h2>
            {tasks?.map((item: any, index: number) => {
              const link = JSON.parse(item?.link) || null
              return link?.url ? (
                <div key={index}>
                  <DefaultLink className="underline font-FuturaLig hover:text-primary" to={link.url}>
                    {item.title}
                  </DefaultLink>
                </div>
              ) : (
                <p key={index} className="font-FuturaLig">
                  {item.title}
                </p>
              )
            })}
          </div>
          {sections.length > 0 && (
            <div className="mb-8">
              <p className="font-FuturaLig">{strings.sections}</p>
              {sections?.map((item: any, index: number) => {
                const link = JSON.parse(item?.link) || null
                return link?.url ? (
                  <div key={index}>
                    <DefaultLink className="underline font-FuturaLig hover:text-primary" to={link.url}>
                      {item.title}
                    </DefaultLink>
                  </div>
                ) : (
                  <p key={index} className="font-FuturaLig">
                    {item.title}
                  </p>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default PersonCard
