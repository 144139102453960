import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType, Terms } from '@/types.d'
import PersonCard from '@/components/PersonCard'

interface Person {
  title: string
  email: string
  id: string
  phone: string
  secondaryPhone: string
  sectionLink: string
  img: any
  tasks: any
  peoplesections: any
  titles: {
    nodes: Array<Terms>
  }
}

interface StaffData {
  data: {
    page: {
      title: string
      heroFilter: boolean
      uri: string
      seo: SeoType
      featuredImage: any
      useEng: boolean
      staff: Array<Person>
    }
    menu?: any
    quickmenu?: any
  }
  pageContext: {
    postlang: string
  }
}

const StaffTemplate = ({ data }: StaffData) => {
  // Page data
  const { page } = data

  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.featuredImage,
    filter: page?.heroFilter,
    disableBackButton: true,
  }
  const personStrings = page?.useEng
    ? { tasks: 'Tasks', sections: 'Secretary General for following sections:' }
    : { tasks: 'Tehtävät', sections: 'Asiamies jaostossa:' }

  return (
    <Layout title={page?.title} hero={heroContent} nav={menu} quickNav={quickmenu} seo={page?.seo} uri={page?.uri}>
      <div className="mb-32 mt-10">
        {page?.staff.map(person => {
          return (
            <PersonCard
              key={person.id}
              img={person?.img?.node}
              name={person.title}
              email={person.email}
              phone={person.phone}
              secondaryPhone={person.secondaryPhone}
              tasks={person.tasks}
              sections={person.peoplesections}
              titles={person.titles}
              strings={personStrings}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query StaffPageById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      useEng: gwStaffLanguageSelector
      heroFilter: gwShowPostHeaderFilter
      ...seo
      ...featuredHeroImage
      staff: gwStaffSelector {
        img: featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 40, transformOptions: { cropFocus: CENTER })
              }
            }
          }
        }
        id
        title
        email: gwPeopleContactDetailsEmail
        phone: gwPeopleContactDetailsPhone
        secondaryPhone: gwPeopleContactDetailsPhoneSecond
        peoplesections: gwPeopleSection {
          title
          link
        }
        tasks: gwPeopleTaskes {
          link
          title
        }
        titles: gwPeopleJobTitles {
          nodes {
            name
            id
          }
        }
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default StaffTemplate
